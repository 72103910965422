import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEdit: boolean;
  character: string | null;
  token: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AvatarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserAvatarId: string = "";
  createUserAvatarId: string = "";
  updateUserAvatarId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEdit: false,
      character: null,
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    //Condition to get api token
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => this.getUserAvatar());
    }

    // Condition for creating user avatar.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.createUserAvatarId
    ) {
      this.createUserAvatarId = "";
      if (responseJson && responseJson.data) {
        this.getUserAvatar();
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Condition to update user avatar details.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.updateUserAvatarId
    ) {
      this.updateUserAvatarId = "";

      if (responseJson && responseJson.data) {
        this.getUserAvatar();
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Condition to fetch user avatar
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getUserAvatarId
    ) {
      this.getUserAvatarId = "";
      if (responseJson && responseJson.data) {
        const {
          body,
          bodyColor,
          eyes,
          facialHair,
          bgc,
          facialHairColor,
          hair,
          hairColor,
          mouth,
          nose,
          open,
          skinColor,
        } = responseJson.data.attributes.avatar_data;
        const characterString = this.getCharacter(
          body,
          bodyColor,
          eyes,
          facialHair,
          bgc,
          facialHairColor,
          hair,
          hairColor,
          mouth,
          nose,
          skinColor
        );
        this.setState({
          character: characterString,
        });
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.errors) {
        //Check Error Response
        return this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  // Function to fetch the avatar detail from API
  async getUserAvatar() {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getUserAvatarId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAvatarApiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }

  // Function to update he User Avatar and send it to API
  updateUserAvatar = (character: string) => {
    const characters: string[] = character.toString().split("|");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const httpBody = this.getAvatarHttpBody(characters);

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateUserAvatarId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserAvatarApiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  };

  // Function to create new User Avatar and send to API
  createUserAvatar = (character: string) => {
    const characters: string[] = character.toString().split("|");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const httpBody = this.getAvatarHttpBody(characters);

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createUserAvatarId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserAvatarApiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  };

  getCharacter = (
    body: string,
    bodyColor: string,
    eyes: string,
    facialHair: string,
    bgColor: string,
    facialHairColor: string,
    hair: string,
    hairColor: string,
    mouth: string,
    nose: string,
    skinColor: string
  ) => {
    return `${body}|${eyes}|${facialHair}|${hair}|${mouth}|${nose}|${bgColor}|${skinColor}|${hairColor}|${facialHairColor}|${bodyColor}`;
  };

  onSaveHandle = (avatarUri: string, character: string) => {
    if (this.state.character !== null) {
      this.updateUserAvatar(character);
    } else {
      this.createUserAvatar(character);
    }
    this.setState({
      character: character,
      isEdit: false,
    });
  };

  getAvatarHttpBody = (characters: string[]) => {
    const httpBody = {
      user_avatar: {
        avatar_data: {
          body: characters[0],
          eyes: characters[1],
          facialHair: characters[2],
          hair: characters[3],
          mouth: characters[4],
          nose: characters[5],
          bgc: characters[6],
          skinColor: characters[7],
          hairColor: characters[8],
          facialHairColor: characters[9],
          bodyColor: characters[10],
          open: null,
        },
      },
    };
    return httpBody;
  };

  onEditHandle = () => {
    this.setState({ isEdit: true });
  };
  // Customizable Area End
}
